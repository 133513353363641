import React from 'react'
import Toast from "react-notification-alert-component";

const NotificationAlert = (props) => {

const testList = [
    props.notification
];

  return (
    <>
        {props.notification.type && 
        <Toast
            toastList={testList}
            position="top-right"
            autoDelete={false}
            autoDeleteTime={3000}
        />
        }
    </>
  )
}

export default NotificationAlert