import React, { useState } from "react"; 
import Home from "./Home";
import Header from './components/Header';
import Footer from './components/Footer'; 
import { 
  BrowserRouter as Router,
  Switch, 
  Route,  
} from "react-router-dom";     
import Portfolio from "./components/Portfolio";
import PortfolioSubscribe from "./components/PortfolioSubscribe";
import Login from "./components/Login";
import Signup from "./components/Signup";
import NotificationAlert from "./components/NotificationAlert";
import Courses from "./components/Courses";
import CourseDetail from "./components/CourseDetail";
import ContactUs from "./components/ContactUs";
import Event from "./components/Event";
import EventDetail from "./components/EventDetail";



const App =()=> { 

    const [progress, setProgress] = useState(0);  
    const [userlogin, setUserlogin] = useState(localStorage.getItem("all_users")?true:false);  
    const [notification, setNotification] = useState({title: "", description: "", type: ""}); 

    const [events, setEvents] = useState([])

    
    return (
        <>
        <Router>
            <NotificationAlert notification={notification}/>
           <Header setNotification={setNotification} setUserlogin={setUserlogin} userlogin={userlogin} progress={progress}/>  
            <Switch> 
                <Route exact path="/">
                    <Home setProgress={setProgress}/>
                </Route>  
                <Route exact path="/portfolio">
                    <Portfolio setNotification={setNotification} setUserlogin={setUserlogin} userlogin={userlogin} setProgress={setProgress}/>
                </Route>  
                <Route exact path="/portfolio-subscribe">
                    <PortfolioSubscribe setProgress={setProgress} />  
                </Route>  
                <Route exact path="/login">
                    <Login setNotification={setNotification} setUserlogin={setUserlogin} setProgress={setProgress} />  
                </Route>    
                <Route exact path="/sign-up">
                    <Signup setNotification={setNotification} setUserlogin={setUserlogin} setProgress={setProgress} />  
                </Route>  
                <Route exact path="/courses">
                    <Courses setNotification={setNotification} setUserlogin={setUserlogin} setProgress={setProgress} />  
                </Route> 
 
                <Route exact path="/contact-us">
                    <ContactUs setNotification={setNotification} setUserlogin={setUserlogin} setProgress={setProgress} />  
                </Route>  
                <Route exact path="/events">
                    <Event setNotification={setNotification} setUserlogin={setUserlogin} setProgress={setProgress} />  
                </Route>  

                <Route exact path="/event/:eventId" render={ (props) => {
                    
                    let idEvent = props.location.pathname.replace('/event/', '')
 
                    
                    let filter;
                    filter = events.filter(post => (
                        post.id === Number(idEvent)
                    )) 

                    return(
                        <EventDetail eventId={idEvent}  setNotification={setNotification} setUserlogin={setUserlogin} setProgress={setProgress}  />
                    )
                }} />


                <Route exact path="/course/:slug" render={ (props) => {
                    
                    let cslug = props.location.pathname.replace('/course/', '') 

                    return(
                        <CourseDetail cslug={cslug}  setNotification={setNotification} userlogin={userlogin} setUserlogin={setUserlogin} setProgress={setProgress}  />
                    )
                }} /> 


            </Switch>
            <Footer/>
        </Router>
        </>
    ); 
}

export default App;