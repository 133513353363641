import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'

const Event = (props) => {

    const [events, setEvents] = useState([])


    useEffect((props) => {
        // document.title = `${capitalizeFirstLetter(props.category)} - NewsMonkey`;
        eventList(); 
        // eslint-disable-next-line
    }, [])


    const eventList = async ()=> {

        props.setProgress(10);
        
        const url = process.env.REACT_APP_API_ADMIN_URL+"api/event/list";
        //const url = `https://newsapi.org/v2/top-headlines?country=${props.country}&category=${props.category}&apiKey=${props.apiKey}&page=${page}&pageSize=${props.pageSize}`; 
        //setLoading(true)
        let data = await fetch(url);
        props.setProgress(30);
        let parsedData = await data.json()
        //console.log(parsedData.data.data);
        props.setProgress(70);
        setEvents(parsedData.data.data)
        //setTotalResults(parsedData.totalResults)
        //setLoading(false)
        props.setProgress(100);
    }

    return (

        <>

        <Helmet>
            <title>Events | Risehighco</title>
            <meta name="description" content="Smart Techno with over 9 Years of expertise in IT industry is one of the Most trusted Stores in Jaipur." />
            <meta name="keywords" content="nline Smart Techno, computer parts" />
        </Helmet>

        <section className="page-title-section-two">
            <div className="pattern-layer-one" style={{backgroundImage:"url(assets/icons/icon-5.png)"}}></div>
            <div className="pattern-layer-two" style={{backgroundImage:"url(assets/icons/icon-6.png)"}}></div>
            <div className="pattern-layer-three" style={{backgroundImage:"url(assets/icons/icon-4.png)"}}></div>
            <div className="pattern-layer-four" style={{backgroundImage:"url(assets/images2/icons/icon-29.png)"}}></div>
            <div className="auto-container"> 
                <ul className="page-breadcrumb">
                    <li><Link to="/">Home</Link></li>
                    <li>Event List</li>
                </ul>
                <div className="content-box">
                    <h2>Event List</h2>
                </div>
            </div>
        </section>  

        <section className="event-page-section">
            <div className="pattern-layer-three" style={{backgroundImage:"url(assets/icons/icon-8.png)"}}></div>
            <div className="auto-container">

                <div className="row clearfix">

                    {events.map((element) => {
                        return  <div className="event-block-two col-lg-4 col-md-6 col-sm-12">
                        <div className="inner-box">
                            <div className="image">
                                <Link to={`/event/${element.id}`}><img style={{width:"370px",height:"278px"}} src={element.image} alt={element.name} /></Link>
                            </div>
                            <div className="lower-content">
                                <div className="category">{element.location}</div>
                                <h4><Link to={`/event/${element.id}`}>{element.name}</Link></h4>
                                <div className="clearfix">
                                    <div className="pull-left">
                                        <div className="post-date">{element.date}</div>
                                    </div>
                                    <div className="pull-right">
                                        {element.is_event_available?<>
                                            <Link className="attend-link" to={`/event/${element.id}`}>Attend</Link>
                                        </>:<>
                                        <Link className="attend-link" to={`/event/${element.id}`}>Event</Link>
                                        </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    })} 
                    
                </div>
            </div>
        </section>
        </>
    )
}

export default Event