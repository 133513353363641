import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const downloadEmployeeData = (props) => {
    
    
    fetch(process.env.REACT_APP_API_ADMIN_URL+'api/portfolio/subscribe/pdf')

        .then(res => res.json())
        .then(
            (result) => { 
 
                //props.setProgress(50);
                window.open(result.data.pdf_url, '_blank'); 
            },
            (error) => {
                this.setState({
                isLoaded: true,
                error
                });
            }
        ); 
};

const PortfolioSubscribe=()=> {  

    return ( 
        <> 

        <Helmet>
            <title>Portfolio Subscribe | Risehighco</title>
            <meta name="description" content="Smart Techno with over 9 Years of expertise in IT industry is one of the Most trusted Stores in Jaipur." />
            <meta name="keywords" content="nline Smart Techno, computer parts" />
        </Helmet>

        <section className="page-title-section">
            <div className="icon-layer-one" style={{backgroundImage:"url(assets/images/icons/icon-5.png)"}}></div>
            <div className="icon-layer-two" style={{backgroundImage:"url(assets/images/icons/icon-6.png)"}}></div>
            <div className="icon-layer-three" style={{backgroundImage:"url(assets/images/icons/icon-4.png)"}}></div>
            <div className="icon-layer-four" style={{backgroundImage:"url(assets/images/icons/icon-7.png)"}}></div>
            <div className="icon-layer-five" style={{backgroundImage:"url(assets/images/icons/icon-7.png)"}}></div>
            <div className="icon-layer-six" style={{backgroundImage:"url(assets/images/icons/icon-8.png)"}}></div>
            <div className="auto-container"> 
                <ul className="page-breadcrumb">
                    <li><Link to="/">Home</Link></li>
                    <li>Portfolio</li>
                </ul>
                <div className="content-box">
                    <h2>Rise High Modern Portfolio</h2>
                    <div className="text">Investing in the stock market is the most common way where committing money or capital with hoping that it will grow over time.However, investing also comes with the risk of losses.</div>
                </div>
            </div>
        </section>

        <section style={{paddingBottom:'0px'}} className="course-detail-section">
		    <div className="auto-container">
			    <div className="row clearfix"> 
                    <div className="content-column col-lg-12 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <h5>Stock Selection Portfolio</h5>
                             
                            <div className="row clearfix"> 

                                <div className="float-left col-lg-4 col-md-12 col-sm-12">
                                    <div className="learn-box">                                  
                                        <ul className="learn-list">
                                            <li>TYPES OF CHARTS</li>
                                            <li>TYPES OF CANDLES</li>
                                            <li>GAP THEORY</li>
                                            <li>CANDLESTICK FORMATION</li>
                                            <li>INDICATORS</li>
                                            <li>TRADING PSYCHOLOGY</li>
                                        </ul>
                                    </div>
                                </div>
                                
                                <div className="float-left col-lg-4 col-md-12 col-sm-12">
                                    <div className="learn-box"> 
                                        <ul className="learn-list">
                                            <li>TYPES OF CHARTS</li>
                                            <li>TYPES OF CANDLES</li>
                                            <li>GAP THEORY</li>
                                            <li>CANDLESTICK FORMATION</li>
                                            <li>INDICATORS</li>
                                            <li>TRADING PSYCHOLOGY</li>
                                        </ul>
                                    </div>
                                </div> 

                                <div className="float-left col-lg-4 col-md-12 col-sm-12">
                                    <div className="learn-box"> 
                                        <ul className="learn-list">
                                            <li>TYPES OF CHARTS</li>
                                            <li>TYPES OF CANDLES</li>
                                            <li>GAP THEORY</li>
                                            <li>CANDLESTICK FORMATION</li>
                                            <li>INDICATORS</li>
                                            <li>TRADING PSYCHOLOGY</li>
                                        </ul>
                                    </div>
                                </div> 

                            </div>

                        </div>

                        <div className="text-center col-lg-12 col-md-12 col-sm-12">
                            <div className="btn-box">
                                <Link to="#" onClick={downloadEmployeeData} className="theme-btn btn-style-five">Technical Analysis Report</Link>
                            </div>
                        </div>
                        
                    </div> 
                    
			    </div>
                
		    </div>

            
	    </section>



        

            
        </>
    ) 
}
export default PortfolioSubscribe;