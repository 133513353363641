import React, { useEffect, useState } from 'react';  
import { Link } from 'react-router-dom';  
import $ from 'jquery';  
import useRazorpay from "react-razorpay"; 
import { useHistory } from 'react-router-dom';
 
const CourseDetail=(props)=> {  

    const [coursedata, setCourse] = useState([]);
    const [course_includes, setCourseIncludes] = useState([]);
    const [other_includes, setOtherIncludes] = useState([]);
    const [what_you_learn, setWhatYouLearn] = useState([]);
    const [requirements, setRequirements] = useState([]);
    const [coursecontent, setCourseContent] = useState([]);

    useEffect((props) => { 
        courseDetailApi();  
    }, [])  

    const Razorpay = useRazorpay();
    let history = useHistory(); 

    let all_users = JSON.parse((localStorage.getItem("all_users")));
    let user_id = (all_users)?all_users.id:'';   


    const courseDetailApi = async (id)=> { 

        props.setProgress(10);
        
        const url = process.env.REACT_APP_API_ADMIN_URL+"api/course/detail/"+props.cslug;
        let data = await fetch(url);
        props.setProgress(30);
        let parsedData = await data.json() 
        props.setProgress(70);
        setCourse(parsedData.data); 
        setCourseIncludes(parsedData.data.course_includes); 
        setOtherIncludes(parsedData.data.inclusive); 
        setWhatYouLearn(parsedData.data.what_you_learn); 
        setRequirements(parsedData.data.requirements); 
        setCourseContent(parsedData.data.course_content); 
 
        props.setProgress(100);

        //console.log(parsedData.data);
    }  
 

    const verifyPaymentSignature = async (razorpay_order_id,razorpay_payment_id,razorpay_signature,user_id,course_id) => {  

        props.setProgress(60);

        const response = await fetch(process.env.REACT_APP_API_ADMIN_URL+"api/razorpay/course_buy", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                razorpay_order_id: razorpay_order_id,
                razorpay_payment_id: razorpay_payment_id,
                razorpay_signature: razorpay_signature,
                user_id: user_id,
                course_id: course_id 
            })
        });

        props.setProgress(90);

        const json = await response.json(); 

        if(json.success) {
            props.setNotification({title: json.message, description: "", type: "success"}); 
            props.setProgress(100); 
            //localStorage.setItem('portfolio_subscription',1);  
            //history.push("/portfolio-subscribe"); 
            return true; 
        } else {
            props.setNotification({title: 'Your payment failed., please correct errors.', description: "", type: "danger"});
            props.setProgress(100);
            return ''; 
        } 
        
    }  

    const handlePayment = async (params) => {  

        const createOrder = async (amount) => {  

            const response = await fetch(process.env.REACT_APP_API_ADMIN_URL+"api/razorpay/ordercreate", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    amount: amount, 
                })
            });

            const json = await response.json();

            if(json.success) {
                return json.data.id;  
            } else {
                props.setNotification({title: 'Your payment failed., please correct errors.', description: "", type: "danger"});
                return ''; 
            }  
        
        }        

        const order = await createOrder(coursedata.course_buy_price); //  Create order on your backend

        //alert(order);

        const options = {
            key: process.env.REACT_APP_API_RZP_KEY, // Enter the Key ID generated from the Dashboard
            amount: coursedata.course_buy_price, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: "INR",
            name: coursedata.name,
            description: '#'+coursedata.id+' '+coursedata.name,
            image: process.env.REACT_APP_API_ADMIN_URL+"assets/images/logo.png",
            order_id: order, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
            handler: function (response) {

                props.setProgress(30);

                verifyPaymentSignature(response.razorpay_order_id,response.razorpay_payment_id,response.razorpay_signature,user_id,coursedata.id);
                
            },
            prefill: {
                name: all_users.name,
                email: all_users.email,
                contact: all_users.mobile,
            }, 
            theme: {
                color: "#63caff",
            },
        };

        const rzp1 = new Razorpay(options);
        
        rzp1.on("payment.failed", function (response) {
            props.setNotification({title: 'Your payment failed., please correct errors.', description: "", type: "danger"});  
        });
        
        rzp1.open();  

    };



    const changeToggle = async (id)=> { 
        
        $('.acc-content-comman').removeClass('current');
        $('.course-details-'+id).addClass('current');
        
        $('.course-btn-comman').removeClass("fa fa-angle-down");
        $('.course-btn-comman').addClass("fa fa-angle-up");

        if($('.course-btn-'+id).hasClass("fa fa-angle-down")) { 
            $('.course-btn-'+id).removeClass("fa fa-angle-down");
            $('.course-btn-'+id).addClass("fa fa-angle-up");
        } else {
            $('.course-btn-'+id).removeClass("fa fa-angle-up");
            $('.course-btn-'+id).addClass("fa fa-angle-down");          
        }
 
    }
 
 

    return (
        <> 

        <section className="cource-detail-banner-section">
            <div className="pattern-layer-one" style={{backgroundImage:"url(assets/images/icons/icon-5.png)"}}></div>
            <div className="pattern-layer-two" style={{backgroundImage:"url(assets/images/icons/icon-6.png)"}}></div>
            <div className="pattern-layer-three" style={{backgroundImage:"url(assets/images/icons/icon-4.png)"}}></div>
            <div className="pattern-layer-four" style={{backgroundImage:"url(assets/images/icons/icon-8.png)"}}></div>
            <div className="auto-container"> 
                <ul className="page-breadcrumb">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="courses">Courses</Link></li>
                    <li>{coursedata.name}</li>
                </ul>
                <div className="content-box"> 
                    <h2>{coursedata.name}</h2>
                    <ul className="course-info">
                        <li><span className="icon fa fa-clock-o"></span>Last Update : {coursedata.last_update}</li>
                        <li><span className="icon fa fa-language"></span>{coursedata.laguage}</li>
                        <li><span className="icon fa fa-user"></span>0 students</li>
                    </ul>
                    <div className="development">{coursedata.created_by}</div>
                    <div className="rating">
                        <span className="fa fa-star"></span>
                        <span className="fa fa-star"></span>
                        <span className="fa fa-star"></span>
                        <span className="fa fa-star"></span>
                        <span className="fa fa-star-o"></span>
                        <strong>4.9</strong>
                        <i>(70 Review)</i>
                    </div>
                    <div className="hovers">{coursedata.hours} Hours . All Levels</div>
                    
            
                    <ul className="social-box">
                        <span className="fa fa-share-alt"></span>
                        <li className="twitter"><a rel="noreferrer" target="_blank" href="http://twitter.com/" className="fa fa-twitter">&nbsp;</a></li>
                        <li className="pinterest"><a rel="noreferrer" target="_blank" href="http://pinterest.com/" className="fa fa-pinterest-p">&nbsp;</a></li>
                        <li className="facebook"><a rel="noreferrer" target="_blank" href="https://www.facebook.com/risehighco/" className="fa fa-facebook-f">&nbsp;</a></li>
                        <li className="dribbble"><a rel="noreferrer" target="_blank" href="http://dribbble.com/" className="fa fa-dribbble">&nbsp;</a></li>
                    </ul>
                    
                </div>
            </div>
        </section> 
        
        
        <section className="course-detail-section">
            <div className="auto-container">
                <div className="row clearfix">
                    
                    <div className="content-column col-lg-8 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <h5>Courses Details</h5>
                            <p>{coursedata.content}</p>
                            <div className="learn-box">
                                <h5>What you'll learn</h5>
                                {(what_you_learn.length>0)?
                                    <ul className="learn-list"> 
                                        {what_you_learn.map((listelement) => { 
                                            return <li key={listelement}>{listelement}</li>
                                        })} 
                                    </ul>
                                :''}  
                            </div>

                            <h5>Requirements</h5>

                            {(requirements.length>0)?
                                <ul className="learn-list-two"> 
                                    {requirements.map((listelement) => { 
                                        return <li key={listelement}>{listelement}</li>
                                    })} 
                                </ul>
                            :''}  

                            <h5>Course Content</h5>
                            <div className="total-lectures">{coursedata.course_section_count} sections • {coursedata.course_lectures_count} lectures</div>
                            

                            {(coursecontent.length>0)?
                                <ul className="accordion-box-two"> 
                                    {coursecontent.map((listelement) => { 
                                        //console.log(listelement);
                                        let contentdataval = listelement.content_data
                                       
                                        return <li key={listelement.id} className="accordion block">
                                            <div onClick={(e) => changeToggle(listelement.id)} className="acc-btn">{listelement.title}<span className="side-text">{listelement.short_description} </span> <div  className={'icon fa fa-angle-down course-btn-comman course-btn-'+listelement.id}></div></div>
                                            <div className={'acc-content acc-content-comman course-details-'+listelement.id}>
                                                <div className="content">
                                                    {(contentdataval.length>0)?
                                                        <ul className="accordion-list"> 
                                                            {contentdataval.map((contentval,key) => { 
                                                                return <li key={key}>
                                                                    <a rel="noreferrer" target="_blank" href={contentval.link} >
                                                                        <span className={'list-icon '+ contentval.icon}></span>{contentval.title} <span className="time">{contentval.description}</span>
                                                                    </a>
                                                                    </li>
                                                          })} 
                                                        </ul>
                                                    :''}  
                                                </div>
                                            </div>
                                        </li>
                                    })} 
                                </ul>
                            :''} 
                            

                        
                            <div className="read-more">
                                <a href="https://www.stat.purdue.edu/~wang913/Projects%20and%20Talks/Technical%20Analysis%201.pdf">Syllabus</a>
                            </div>
                            
                            <h5>Instructor</h5>
                            
                    
                            <div className="author-box">
                                <div className="box-inner">
                                    <div className="image">
                                        <img src="/assets/images/resource/professional.jpg" alt="" />
                                    </div>
                                    <h6>Shatabdi Saxena <Link to="#" className="icon fa fa-plus"></Link></h6>
                                    <div className="designation">Financial Advisor/ Trainer</div>
                                    <ul className="list">
                                        <li><span className="icon fa fa-play-circle-o"></span>9 Course</li>
                                        <li><span className="icon fa fa-star-o"></span>4.6 Instructor Rating</li>
                                        <li><span className="icon fa fa-user"></span>173 Students</li>
                                    </ul>
                                    <div className="text">More than 10 years of quality experience in the Financial Market. She was also associated with esteem organisations like Sherkhan and ICICI Securities.</div>
                                </div>
                            </div>
                            
                    
                            <div className="comments-area">
                                <div className="group-title">
                                    <h5>Course Review</h5>
                                </div>
                                
                                <div style={{display:'none'}} className="comment-box">
                                
                                    <div className="comment">
                                        <div className="author-thumb"><img src="assets/images/resource/author-5.jpg" alt=""/></div>
                                        <div className="comment-info clearfix">
                                            <strong>Shopnil Mahadi</strong>
                                            <div className="rating">
                                                <span className="fa fa-star"></span>
                                                <span className="fa fa-star"></span>
                                                <span className="fa fa-star"></span>
                                                <span className="fa fa-star"></span>
                                                <span className="fa fa-star-o"></span>
                                            </div>
                                            <div className="comment-time">3 weeks ago</div>
                                            <ul className="like-dislike">
                                                <li><Link to="#" className="flaticon-like-1">&nbsp;</Link></li>
                                                <li><Link to="#" className="flaticon-dislike">&nbsp;</Link></li>
                                            </ul>
                                        </div>
                                        <div className="text"> I have been identified as one of LebariTop Instructors and all my premium courses have recently earned the best-selling status for outstanding performance and student satisfaction.</div>
                                    </div>
                                    
                                </div>
                            </div> 
                            
                            <div className="comment-form">
                                        
                                <div className="group-title"><h5>Leave A Comment</h5></div>
                                
                            
                                <form method="post">

                                    <div className="row clearfix">
                                        
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <input type="text" name="username" placeholder="Full Name" required=""/>
                                        </div>
                                        
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <input type="email" name="email" placeholder="Email" required=""/>
                                        </div>
                                        
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <input type="text" name="subject" placeholder="Subject" required=""/>
                                        </div>
                                        
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <textarea className="darma" name="message" placeholder="Your Message"></textarea>
                                        </div>
                                        
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <button className="theme-btn btn-style-five" type="submit" name="submit-form">Write A Review</button>
                                        </div>
                                        
                                    </div>
                                </form>
                                    
                            </div>
                            
                        </div>
                    </div>
                    
                    <div className="info-column col-lg-4 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="price">₹{coursedata.discount_price} <i>₹{coursedata.price}</i></div>
                            <h5>This course includes:</h5>
                            <div className="text">{coursedata.small_description}</div> 

                            {(course_includes.length>0)?
                            <ul className="level-list"> 
                                {course_includes.map((listelement) => { 
                                    return <li key={listelement}>{listelement}</li>
                                })} 
                            </ul>
                            :''} 

                            <h5>Other includes:</h5> 
                            {(other_includes.length>0)?
                            <ul className="level-list-two"> 
                                {other_includes.map((listelement) => { 
                                    return <li key={listelement}>{listelement}</li>
                                })} 
                            </ul>
                            :''}  
 
                            <div className="btns-box">
                                {props.userlogin?<><button className="theme-btn enrol-btn" type="button" onClick={handlePayment}>Enrol Now</button></>:<><Link to="/login" className="theme-btn enrol-btn">Enrol Now</Link></> }
                            </div>

                        </div>
                    </div>
                    
                </div>
            </div>
        </section> 
 
        
        </>
    ) 
}
         
export default CourseDetail;