import React from 'react'; 
import { Link } from 'react-router-dom'; 

const Footer=()=> { 
    return (
        <> 

        <footer className="footer" style={{backgroundImage:"url(assets/images/footer/bg.png)"}}>
            <div className="footer__wrapper padding-top padding-bottom">
                <div className="container">
                    <div className="footer__content text-center"> 
                        <Link to="/" className="mb-4 d-inline-block">
                            <img src={process.env.PUBLIC_URL + '/assets/images/logo/smp-logo.png'} alt="Logo"/>
                        </Link>
                        <ul className="social justify-content-center">
                            <li className="social__item"> 
                                <a href="https://www.youtube.com/@SmartTechnoIT"  className="social__link" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-youtube"></i>
                                </a>
                            </li>
                            <li className="social__item">
                                <a href="#"  className="social__link" target="_blank" rel="noopener noreferrer"> 
                                    <i className="fab fa-instagram"></i>
                                </a> 
                            </li>
                            <li className="social__item">
                                <a href="#"  className="social__link" target="_blank" rel="noopener noreferrer"> 
                                    <i className="fab fa-facebook-f"></i>
                                </a> 
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="footer__copyright">
                <div className="container">
                    <div className="text-center py-4">
                        <p className=" mb-0">Sellmypc© 2023 | All Rights Reserved </p>
                    </div>
                </div>
            </div>
        </footer>          

        <a href="https://wa.me/919982012228"  className="scrollToTop" rel="noopener noreferrer">
            <img src="assets/icons/whatsapp.svg" alt="whatsapp"/>
        </a>  

        </>
    ) 
}
export default Footer;