import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';
import ContactForm from './ContactForm'

const ContactUs=(props)=> { 

    useEffect(() => { 
        props.setProgress(100);  
    }); 


    return (
        <>

            <Helmet>
                <title data-react-helmet="true">Sellmypc — Get Cash For Selling Computer & Parts</title>
                <meta data-react-helmet="true" name="description" content="Sellmypc is a platform where you can sell your computer,laptop and computer parts at best prices or get them exchanged for newer components. Receive the amount into your Bank Account or Wallet." />
                <meta data-react-helmet="true" name="keywords" content="sell my pc, sell my graphic card, sell my cpu, i want to sell, exchange pc parts, sell my pc in india, sell my gaming pc" />
            </Helmet>


            <section className="page-header bg--cover" style={{backgroundImage:"url(assets/images/banner/contactus2.jpg)"}}>
                <div className="container">
                    <div className="page-header__content text-center">
                        <h2 className="text-capitalize">Contact Us</h2>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>
 
            <section className="contact padding-top padding-bottom">
                <div className="container">
                    <div className="section-header section-header--middle">
                        <div className="section-header__content">
                            <div className="section-header__titlebar">
                                <h2 className="section__header__title"> Get In Touch
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="contact__wrapper">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">

                                <ContactForm setNotification={props.setNotification}/> 
 
                            </div>
                        </div>

                    </div>
                </div>
            </section>  

 
            <section className="cta padding-top padding-bottom" data-aos="fade-up" data-aos-duration="1000">
                <div className="container">
                    <div className="cta__wrapper">
                        <div className="cta__content">
                            <p className="section-header__subtitle"> Got anything to sell?</p>
                            <h2 className="section__header__title"> Sell now for cash</h2>
                            <a href="https://wa.me/919982012228" className="default-btn" rel="noopener noreferrer"><span>Click Here!</span></a>
                        </div>
                    </div>
                </div> 
            </section>  
        
        </>
    ) 
}
export default ContactUs;