import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const EventDetail = (props) => {

    const [eventdata, setEvent] = useState([]);
	const [eventtag, setTag] = useState([]);
	const [eventimage, setImage] = useState([]);
	const [eventvideo, setVideo] = useState([]);


    useEffect((props) => {
        // document.title = `${capitalizeFirstLetter(props.category)} - NewsMonkey`;
        eventDetail(); 
        // eslint-disable-next-line
    }, [])


    const eventDetail = async (id)=> {

        // alert(props.eventId);

        props.setProgress(10);
        
        const url = process.env.REACT_APP_API_ADMIN_URL+"api/event/detail/"+props.eventId;
        //const url = `https://newsapi.org/v2/top-headlines?country=${props.country}&category=${props.category}&apiKey=${props.apiKey}&page=${page}&pageSize=${props.pageSize}`; 
        //setLoading(true)
        let data = await fetch(url);
        props.setProgress(30);
        let parsedData = await data.json()
        //console.log(parsedData.data.data);
        props.setProgress(70);
        setEvent(parsedData.data);
		setTag(parsedData.data.tag_data);
		setImage(parsedData.data.event_img);
		setVideo(parsedData.data.event_video);
		
        //setTotalResults(parsedData.totalResults)
        //setLoading(false)
        props.setProgress(100);
    } 

	//console.log(eventtag);

  	return ( 

		

    <>
    <section className="event-detail-banner-section">
		<div className="pattern-layer-one" style={{backgroundImage:"url(assets/images/icons/icon-5.png)"}}></div>
		<div className="pattern-layer-two" style={{backgroundImage:"url(assets/images/icons/icon-6.png)"}}></div>
		<div className="pattern-layer-three" style={{backgroundImage:"url(assets/images/icons/icon-4.png)"}}></div>
		<div className="pattern-layer-four" style={{backgroundImage:"url(assets/images/icons/icon-8.png)"}}></div>
		<div className="auto-container">
			
			<ul className="page-breadcrumb">
				<li><Link to="/events">Events</Link></li>
				<li>{eventdata.name}</li>
			</ul>
			<div className="content-box">
				<div className="title">{eventdata.location}</div>
				<h2>{eventdata.name}</h2>
				<div className="event-time-zone">
					<div className="row clearfix">
					 
						<div className="column col-lg-4 col-md-4 col-sm-12">
							<h6>Venue</h6>
							<ul className="course-info">
								<li><span className="icon flaticon-maps-and-flags"></span>{eventdata.venue}</li>
								<li><span className="icon flaticon-user"></span>{eventdata.available_seats} seat</li>
							</ul>
						</div> 
						<div className="column col-lg-4 col-md-4 col-sm-12">
							<h6>Contact details:</h6>
							<ul className="course-info"> 
								<li><span className="icon flaticon-email-2"></span>{eventdata.email}</li>
								<li><span className="icon flaticon-language"></span>{eventdata.laguage}</li>
							</ul>
						</div> 
						<div className="column col-lg-4 col-md-4 col-sm-12">
							<h6>Date & Time:</h6>
							<ul className="course-info">
								<li><span className="icon flaticon-timetable"></span>{eventdata.date}</li>
								<li><span className="icon flaticon-wall-clock"></span>{eventdata.time}</li>
							</ul>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	</section>
 
	<section className="event-detail-section">
		<div className="auto-container">
			<div className="row clearfix">
			 
				<div className="content-column col-lg-8 col-md-12 col-sm-12">
					<div className="inner-column">
						<h5>Event Description</h5>
						<p>
                        {eventdata.content}
                        </p>

						<div className="image">
							<img src={eventdata.image} alt={eventdata.name} />
						</div>

						{(eventdata.is_event_available===1)?
						<div className="tribe-events-cal-links">
							<a rel="noreferrer" target={'_blank'} className="theme-btn btn-style-five" href="https://www.google.com/calendar/event?action=TEMPLATE&amp;dates=20221123T080000/20221123T170000&amp;text=Rise High&amp;trp=false&amp;ctz=UTC+0&amp;sprop=website: Rise High" title="Add to Google Calendar">+ Add to Google Calendar</a>
							&nbsp;&nbsp;<a target={'_blank'} className="theme-btn btn-style-five" href={eventdata.meet_link} title="Join Live Class"> Join Live Class</a>
						</div>:''}

						{eventimage.map((image) =>
							<div className="image">
								<img src={image.image_name} alt={eventdata.name} />
							</div>
						)} 
						  
						
					</div>
				</div>
				 
				<div className="info-column col-lg-4 col-md-12 col-sm-12">
					<div className="inner-column">
						<h5>Event</h5> 

                        <div className="event-widget">
                            <h6>Next Event Date:</h6>
                            <div className="text">
                                {eventdata.next_event_date?<>
                                    {eventdata.next_event_date}
                                </>:""}
                            </div>
                        </div>
							 
						 
						<div className="popular-tags-box">
							<div className="title-box">
								<h5>Tags</h5>
							</div>
							<div className="widget-content">

								{eventtag.map((note) => <Link key={note} to="/">{note}</Link>)} 
 
							</div>
						</div>
						 
						<div className="event-widget">
						 	 
							<div className="event-organizer">
								<div className="organizer">Organizers:</div>
								<ul className="organizer-list">
									<li>
										<strong>{eventdata.organizer_name}</strong>
										Email : {eventdata.email}
									</li>  
								</ul> 
								
							</div>
							
						</div>

 
					</div>

					
				</div>

 
				
				{eventvideo.map((video) => 

					<div className="col-lg-6 col-md-12 col-sm-12"> 

						<div className="inner-column">
							<iframe width="560" height="315" src={video.image_name} frameborder="0" allowfullscreen></iframe>
						</div> 

					</div> 
				)}  
				
				
			</div>
		</div>
	</section>
    </>
  )
}

export default EventDetail