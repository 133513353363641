import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import useRazorpay from "react-razorpay"; 
import { useHistory } from 'react-router-dom';

const Portfolio=(props)=> { 

    const Razorpay = useRazorpay();

    let history = useHistory(); 


    let all_users = JSON.parse((localStorage.getItem("all_users")));
    let user_id = (all_users)?all_users.id:'';  
    


    const verifyPaymentSignature = async (razorpay_order_id,razorpay_payment_id,razorpay_signature,user_id) => {  

        props.setProgress(60);

        const response = await fetch(process.env.REACT_APP_API_ADMIN_URL+"api/razorpay/verify_payment_signature", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                razorpay_order_id: razorpay_order_id,
                razorpay_payment_id: razorpay_payment_id,
                razorpay_signature: razorpay_signature,
                user_id: user_id 
            })
        });

        props.setProgress(90);

        const json = await response.json(); 

        if(json.success) {
            props.setNotification({title: json.message, description: "", type: "success"}); 
            props.setProgress(100); 
            localStorage.setItem('portfolio_subscription',1);  
            history.push("/portfolio-subscribe"); 
            return true; 
        } else {
            props.setNotification({title: 'Your payment failed., please correct errors.', description: "", type: "danger"});
            props.setProgress(100);
            return ''; 
        } 
       
        
    }    


    const handlePayment = async (params) => {  

            const createOrder = async (amount) => {  

            const response = await fetch(process.env.REACT_APP_API_ADMIN_URL+"api/razorpay/ordercreate", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    amount: amount, 
                })
            });
    
            const json = await response.json();

            if(json.success) {
                return json.data.id;  
            } else {
                props.setNotification({title: 'Your payment failed., please correct errors.', description: "", type: "danger"});
                return ''; 
            }  
            
        }        

        const order = await createOrder(50000); //  Create order on your backend

        //alert(order);
    
        const options = {
            key: process.env.REACT_APP_API_RZP_KEY, // Enter the Key ID generated from the Dashboard
            amount: "50000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: "INR",
            name: "Rise High",
            description: "Modern Portfolio",
            image: process.env.REACT_APP_API_ADMIN_URL+"assets/images/logo.png",
            order_id: order, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
            handler: function (response) {

                props.setProgress(30);

                verifyPaymentSignature(response.razorpay_order_id,response.razorpay_payment_id,response.razorpay_signature,user_id);
                

                

            },
            prefill: {
                name: all_users.name,
                email: all_users.email,
                contact: all_users.mobile,
            }, 
            theme: {
                color: "#63caff",
            },
        };
    
        const rzp1 = new Razorpay(options);
        
        rzp1.on("payment.failed", function (response) {
            props.setNotification({title: 'Your payment failed., please correct errors.', description: "", type: "danger"});  
        });
        
        rzp1.open();  
    
    };


    let portfolio_subscription = localStorage.getItem("portfolio_subscription");

    

    //alert(portfolio_subscription);


    return ( 
        <> 

        <Helmet>
            <title>Modern Portfolio | Risehighco</title>
            <meta name="description" content="Smart Techno with over 9 Years of expertise in IT industry is one of the Most trusted Stores in Jaipur." />
            <meta name="keywords" content="nline Smart Techno, computer parts" />
        </Helmet>

        <section className="page-title-section">
            <div className="icon-layer-one" style={{backgroundImage:"url(assets/images/icons/icon-5.png)"}}></div>
            <div className="icon-layer-two" style={{backgroundImage:"url(assets/images/icons/icon-6.png)"}}></div>
            <div className="icon-layer-three" style={{backgroundImage:"url(assets/images/icons/icon-4.png)"}}></div>
            <div className="icon-layer-four" style={{backgroundImage:"url(assets/images/icons/icon-7.png)"}}></div>
            <div className="icon-layer-five" style={{backgroundImage:"url(assets/images/icons/icon-7.png)"}}></div>
            <div className="icon-layer-six" style={{backgroundImage:"url(assets/images/icons/icon-8.png)"}}></div>
            <div className="auto-container"> 
                <ul className="page-breadcrumb">
                    <li><Link to="/">Home</Link></li>
                    <li>Modern Portfolio</li>
                </ul>
                <div className="content-box">
                    <h2>What Is Rise High Modern Portfolio</h2>
                    <div className="text">Modern Portfolio is also known as "Portfolio Selection" <br/> selecting investments with greater returns without a higher level of risk. </div>
                </div>
            </div>
        </section>


        <section className="faq-blocks-section">
            <div className="auto-container">
                <div className="inner-container">
                    <div className="row clearfix">
                         
                        <div className="faq-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <div className="icon-box">
                                    <span className="icon flaticon-unity"></span>
                                </div>
                                <h5><Link to="#">Technical Analysis</Link></h5>
                                <div className="text">Selection of Financial Assets based on Return- Risk Analysis.</div>
                                <Link to="#" className="read-more">Read More</Link>
                            </div>
                        </div>
                         
                        <div className="faq-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <div className="icon-box">
                                    <span className="icon flaticon-calendar"></span>
                                </div>
                                <h5><Link to="#">Diversified Portfolio</Link></h5>
                                <div className="text">Maximize Returns without Anacceptable Levels of Risk</div>
                                <Link to="#" className="read-more">Read More</Link>
                            </div>
                        </div>
                         
                        <div className="faq-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <div className="icon-box">
                                    <span className="icon flaticon-customer"></span>
                                </div>
                                <h5><Link to="#">Investment Recipe</Link></h5>
                                <div className="text">Recipe for Choosing Financial Assets in Portfolio</div>
                                <Link to="#" className="read-more">Read More</Link>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>

        <section className="clients-section-two">
            <div className="auto-container"> 
                <div className="title-box"> 
                    <h2>Plan, Save & Invest For Your Future With <span>Rise High</span> <br/> Modern Portfolio</h2>
                </div> 
            </div>
        </section>


        <section className="connection-section">
            <div className="color-one"></div>
            <div className="pattern-layer" style={{backgroundImage:"url(assets/images2/background/pattern-10.png)"}}></div>
            <div className="icon-layer-one" style={{backgroundImage:"url(assets/images2/icons/icon-4.png)"}}></div>
            <div className="icon-layer-two" style={{backgroundImage:"url(assets/images2/icons/icon-15.png)"}}></div>
            <div className="icon-layer-three" style={{backgroundImage:"url(assets/images2/icons/icon-4.png)"}}></div>
            <div className="icon-layer-four" style={{backgroundImage:"url(assets/images2/icons/pattern-10.png)"}}></div>
            <div className="icon-layer-five" style={{backgroundImage:"url(assets/images2/icons/icon-3.png)"}}></div> 
            <div className="left-color-layer"></div>
            <div className="right-color-layer"></div>
            <div className="auto-container">
                <div className="row clearfix">
                 
                    <div className="content-column col-lg-8 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="sec-title">
                                <h2>Assembling a Portfolio of Assets</h2>
                                <div className="text">how and why investing sooner rather than later can have tremendous <br/> benefits in the long run</div>
                            </div>
                            <div className="row clearfix">
                                 
                                <div className="feature-block-three col-xl-6 col-lg-12 col-md-12 col-sm-12">
                                    <div className="inner-box">
                                        <div className="icon-box">
                                            <span className="icon flaticon-bullhorn"></span>
                                        </div>
                                        <h4><Link to="#">Start Your Investment Journey</Link></h4>
                                        <div className="text">New to Investing who wish to gain experience trading "Invest" in a Morden Portfolio of Stocks. Take an active hand in managing your own money’s growth with with the lowest possible risk.</div>
                                        <Link to="#" className="link">Know More</Link>
                                    </div>
                                </div>
                                 
                                <div className="feature-block-three col-xl-6 col-lg-12 col-md-12 col-sm-12">
                                    <div className="inner-outer">
                                        <div className="inner-box">
                                            <div className="icon-box">
                                                <span className="icon flaticon-mortarboard"></span>
                                            </div>
                                            <h4><Link to="#">Stock Market Diversification</Link></h4>
                                            <div className="text">Invest more diversified than a single stock “Don’t put all of your eggs in one basket.”Unlike investing money for the future, hoping that it will grow over time be investing in your future.</div>
                                            <Link to="#" className="link">Know More</Link>
                                        </div>
                                    </div>
                                </div>
                                 
                                <div className="feature-block-three col-xl-6 col-lg-12 col-md-12 col-sm-12">
                                    <div className="inner-box">
                                        <div className="icon-box">
                                            <span className="icon flaticon-bar-chart"></span>
                                        </div>
                                        <h4><Link to="#">Picking Up The Stocks</Link></h4>
                                        <div className="text">It’s more complicated to select the right stocks as all investing comes with at least some degree of risk key consideration for investors is how to manage risk in order to achieve their financial goals, whether they are short- or long-term.</div>
                                        <Link to="#" className="link">How to find a counsellor</Link>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div> 

                    <div className="video-column col-lg-4 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="color-two"></div> 
                            <div className="video-boxed-two wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <figure className="video-image">
                                    <img src="assets/images2/resource/video-image-1.jpg" alt=""/>
                                </figure>
                                <a href="https://www.youtube.com/watch?v=kxPCFljwJws" className="lightbox-image overlay-box"><span className="flaticon-play-arrow"><i className="ripple"></i></span></a>
                                <i className="time">5.20 m</i>
                            </div>
                        </div>
                        
                    </div>

                    <div className="text-center video-column col-lg-12 col-md-12 col-sm-12">
                        <div className="btn-box">

                            {props.userlogin?(portfolio_subscription)?<><Link to="portfolio-subscribe" className="theme-btn btn-style-five">Portfolio</Link></>:<><button className="theme-btn btn-style-five" type="button" onClick={handlePayment}>Subscribe Now</button></>:<><Link to="login" className="theme-btn btn-style-five">Subscribe Now</Link></> }

                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </section> 

            
        </>
    ) 
}
export default Portfolio;