import React from 'react';
//import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';   
import TopBarLoder from './TopBarLoder'; 
//import { GoogleLogout } from 'react-google-login';

const Header =(props)=> {  
 
    /*
    let history = useHistory();
    const handleLogout = ()=>{
        localStorage.removeItem('all_users');
        props.setUserlogin(false);
        props.setNotification({title: "", description: "", type: ""});  
        history.push('/login');
    } 
    */

    return (
        <> 


        <header className="header-section">
            <TopBarLoder progress={props.progress}/> 
            <div className="container">
                <div className="header-holder">
                    <div className="header-primary d-flex flex-wrap justify-content-between align-items-center">
                        <div className="brand-logo d-none d-lg-inline-block">
                            <div className="logo">
                                <Link to="/">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/logo/smp-logo.png'} alt="logo"/>
                                </Link>
                            </div>
                        </div>
                        <div className="header-wrapper justify-content-lg-end">
                            <div className="mobile-logo d-lg-none">
                                <Link to="/">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/logo/smp-logo.png'} alt="logo"/>
                                </Link>
                            </div>
                            <div className="menu-area">
                                <ul className="menu">
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact-us">Contact Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/about-us">About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="#faq">FAQ</Link>
                                    </li> 
                                </ul>
                                <a className="wallet-btn" href="https://wa.me/919982012228"><span>SELL NOW</span><i className="fa-solid fa-wallet"></i></a>

                                <div className="header-bar d-lg-none">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </header>            
        </>
    ) 
}

export default Header;