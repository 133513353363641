import React, { useEffect } from 'react';  
import { Link } from 'react-router-dom';    
import Helmet from 'react-helmet';
 
const Home=(props)=> {   
  
    useEffect(() => { 

        props.setProgress(100);    
         
    }, []);  
    

    const bannerContentStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    };
    
    

    return (
        <> 

        <Helmet>
            <title>Sellmypc - Get Cash For Selling Computer & Parts</title>
            <meta name="description" content="Sellmypc is a platform where you can sell your computer,laptop and computer parts at best prices or get them exchanged for newer components. Receive the amount into your Bank Account or Wallet." />
            <meta name="keywords" content="sell my pc, sell my graphic card, sell my cpu, i want to sell, exchange pc parts, sell my pc in india, sell my gaming pc" />
        </Helmet>    


        <section className="banner banner--style2" id="home">
            <div className="video-background">
                <video autoPlay muted loop id="background-video">
                    <source src="assets/video/web_video_1.mp4" type="video/mp4"/> 
                </video>
            </div>
            <div className="container">
                <div className="banner__wrapper">
                    <div className="row g-5 justify-content-center">
                        <div className="col-lg-12">
                            <div style={bannerContentStyle} className="banner__content text-center" data-aos="zoom-in" data-aos-duration="900">
                                <h1 style={{paddingTop:"5rem"}}>Sell Your PC / Laptop or Just Some PC parts<br/>at<br/>Sell My Pc</h1>
                                <p>(A unit of SmartTechno)</p>
                                <a href="https://wa.me/919982012228" className="default-btn"><span>Sell Now</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  




        <div className="counter__section counter__section--uplift">
            <div className="container">
                <div className="counter__wrapper" data-aos="fade-up" data-aos-duration="1000">
                    <div className="row g-5 justify-content-center align-items-center">
                        <div className="col-lg-3 col-sm-6">
                            <div className="counter__item">
                                <h3><span data-purecounter-start="0" data-purecounter-end="12"
                                        className="purecounter">12</span>+
                                </h3>
                                <p>Years Of Trust</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="counter__item">
                                <h3><span data-purecounter-start="0" data-purecounter-end="90"
                                        className="purecounter">90</span>+
                                </h3>
                                <p>Cities With Pickup Facility</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="counter__item">
                                <h3><span data-purecounter-start="0" data-purecounter-end="9375"
                                        className="purecounter">9375</span>+
                                </h3>
                                <p>Computers Purchased</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="counter__item">
                                <h3><span data-purecounter-start="0" data-purecounter-end="2433"
                                        className="purecounter">2433</span>+
                                </h3>
                                <p>Laptops Purchased</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <section className="roadmap  padding-top padding-bottom" id="roadmap">
            <div className="container">
                <div className="section-header section-header--middle">
                    <div className="section-header__content">
                        <div className="section-header__titlebar">
                            <p className="section-header__subtitle">Easy & Hassle free</p>
                            <h2 className="section__header__title">Process</h2>
                        </div>
                    </div>
                </div>
                <div className="roadmap__wrapper">
                    <div className="swiper roadmap__slider">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="roadmap__item" data-aos="fade-up" data-aos-duration="1000">
                                    <div className="roadmap__item-inner roadmap__item-inner--vertical-line">
                                        <div className="roadmap__item-year">
                                            <h6>Quick</h6>
                                            <img src="assets/images/roadmap/arrow-icon.png" alt="Arrow Icon"/>
                                        </div>
                                        <div className="roadmap__item-content">
                                            <div className="roadmap__item-icon">1</div>
                                            <div className="roadmap__item-text">
                                                <h4>Share</h4>
                                                <p>Share the details and images of the product/products that you want to sell on whatsapp</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="roadmap__item" data-aos="fade-up" data-aos-duration="1500">
                                    <div className="roadmap__item-inner roadmap__item-inner--vertical-line">
                                        <div className="roadmap__item-year">
                                            <h6>Lucrative</h6>
                                            <img src="assets/images/roadmap/arrow-icon.png" alt="Arrow Icon"/>
                                        </div>
                                        <div className="roadmap__item-content">
                                            <div className="roadmap__item-icon">2</div>
                                            <div className="roadmap__item-text">
                                                <h4>Offer</h4>
                                                <p>After analyzing all details, we would share you the estimated resale value of the products</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="roadmap__item" data-aos="fade-up" data-aos-duration="2000">
                                    <div className="roadmap__item-inner roadmap__item-inner--vertical-line">
                                        <div className="roadmap__item-year">
                                            <h6>Hassle-free</h6>
                                            <img src="assets/images/roadmap/arrow-icon.png" alt="Arrow Icon"/>
                                        </div>
                                        <div className="roadmap__item-content">
                                            <div className="roadmap__item-icon">3</div>
                                            <div className="roadmap__item-text">
                                                <h4>Shipping</h4>
                                                <p>Once you agree with the price, Ship the product to us or we can arrange a pickup for you</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="roadmap__item" data-aos="fade-up" data-aos-duration="2500">
                                    <div className="roadmap__item-inner roadmap__item-inner--vertical-line">
                                        <div className="roadmap__item-year">
                                            <h6>Hefty</h6>
                                            <img src="assets/images/roadmap/arrow-icon.png" alt="Arrow Icon"/>
                                        </div>
                                        <div className="roadmap__item-content">
                                            <div className="roadmap__item-icon">4</div>
                                            <div className="roadmap__item-text">
                                                <h4>Get Cash</h4>
                                                <p>Once the product is received and checked, the payment is made within 24 hours to your account</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="roadmap__slider-prev"><i className="fas fa-angle-left"></i></div>
                    <div className="roadmap__slider-next"><i className="fas fa-angle-right"></i></div>
                </div>
            </div>
        </section>


        <div className="login-section padding-top padding-bottom" id="kyc">
            <div className=" container">
                <div className="account-wrapper">
                    <div className="account-title">
                        <h2>Start Selling</h2>
                        <p>We’ll connect with you through whatsapp </p>
                    </div>
    
                    <form className="account-form" method="post" action="/submit">
                        <div className="form-group">
                            <input type="text" placeholder="First Name" name="name"/>
                        </div>
                        <div className="form-group">
                            <input type="text" placeholder="Last Name" name="name"/>
                        </div>
                        <div className="form-group">
                            <button className="default-btn" type="submit"><span><img src="assets/icons/whatsapp.svg" alt="whatsapp"/> Connect Now</span></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>



        <section className="team padding-top padding-bottom shape-1r" id="team">
            <div className="container">
                <div className="section-header section-header--middle">
                    <div className="section-header__content">
                        <div className="section-header__titlebar">
                            <p className="section-header__subtitle">Our</p>
                            <h2 className="section__header__title">Partenered sites</h2>
                        </div>
                    </div>
                </div>
                <div className="team__wrapper">
                    <div className="row justify-content-center g-4">
                        <div className="col-lg-3 col-sm-6">
                            <div className="team__item" data-aos="fade-up" data-aos-duration="800">
                                <div className="team__thumb">
                                    <img src="assets/images/team/1.png" alt="Team Member Image"/>
                                </div>
                                <div className="team__content">
                                    <h4><Link to="https://smarttechno.in/">smarttechno.in</Link></h4>
                                    <p>Online Store For Custom PC and Parts</p>
                                    <ul className="social">

                                        <li className="social__item"> 
                                            <a href="https://www.youtube.com/@SmartTechnoIT"  className="social__link" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-youtube"></i>
                                            </a>
                                        </li>
                                        <li className="social__item">
                                            <a href="#"  className="social__link" target="_blank" rel="noopener noreferrer"> 
                                                <i className="fab fa-instagram"></i>
                                            </a> 
                                        </li>
                                        <li className="social__item">
                                            <a href="#"  className="social__link" target="_blank" rel="noopener noreferrer"> 
                                                <i className="fab fa-facebook-f"></i>
                                            </a> 
                                        </li> 
                                       
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="team__item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="100">
                                <div className="team__thumb">
                                    <img src="assets/images/team/2.png" alt="Team Member Image"/>
                                </div>
                                <div className="team__content">
                                    <h4><Link to="https://used-laptop.in/">used-laptop.in</Link></h4>
                                    <p>Online Store For Certified Refurbished Laptops</p>
                                    <ul className="social">

                                        <li className="social__item"> 
                                            <a href="https://www.youtube.com/@SmartTechnoIT"  className="social__link" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-youtube"></i>
                                            </a>
                                        </li>
                                        <li className="social__item">
                                            <a href="#"  className="social__link" target="_blank" rel="noopener noreferrer"> 
                                                <i className="fab fa-instagram"></i>
                                            </a> 
                                        </li>
                                        <li className="social__item">
                                            <a href="#"  className="social__link" target="_blank" rel="noopener noreferrer"> 
                                                <i className="fab fa-facebook-f"></i>
                                            </a> 
                                        </li> 
                                         
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="team__item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="150">
                                <div className="team__thumb">
                                    <img src="assets/images/team/3.png" alt="Team Member Image"/>
                                </div>
                                <div className="team__content">
                                    <h4><Link to="https://computerstoreindia.com/">computerstoreindia.com</Link></h4>
                                    <p>One Stop Solution For Computer Solutions</p>
                                    <ul className="social">

                                        <li className="social__item"> 
                                            <a href="https://www.youtube.com/@SmartTechnoIT"  className="social__link" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-youtube"></i>
                                            </a>
                                        </li>
                                        <li className="social__item">
                                            <a href="#"  className="social__link" target="_blank" rel="noopener noreferrer"> 
                                                <i className="fab fa-instagram"></i>
                                            </a> 
                                        </li>
                                        <li className="social__item">
                                            <a href="#"  className="social__link" target="_blank" rel="noopener noreferrer"> 
                                                <i className="fab fa-facebook-f"></i>
                                            </a> 
                                        </li> 
                                         
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>



        <section className="faq padding-bottom shape-1r" id="faq">
            <div className="container">
                <div className="section-header section-header--middle">
                    <div className="section-header__content">
                        <div className="section-header__titlebar">
                            <p className="section-header__subtitle"> Faq</p>
                            <h2 className="section__header__title"> Common Queries</h2>
                        </div>
                    </div>
                </div>
                <div className="faq__wrapper" data-aos="fade-up" data-aos-duration="1000">
                    <ul className="faq__tab nav nav-pills mb-5" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-general-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-general" type="button" role="tab" aria-controls="pills-general"
                                aria-selected="true">Trust</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-token-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-token" type="button" role="tab" aria-controls="pills-token"
                                aria-selected="false">Process</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-security-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-security" type="button" role="tab" aria-controls="pills-security"
                                aria-selected="false">Eligiblity</button>
                        </li>
                    </ul>
                    <div className="tab-content faq__content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-general" role="tabpanel"
                            aria-labelledby="pills-general-tab">
                            <div className="row gy-3 gx-5">
                                <div className="col-lg-6">
                                    <div className="accordion accordion-flush" id="accordionGeneral1">
                                        <div className="accordion-item">
                                            <h6 className="accordion-header" id="general1-headingOne">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#general1-collapseOne"
                                                    aria-expanded="false" aria-controls="general1-collapseOne">
                                                    How can I Trust Sell My PC?
                                                </button>
                                            </h6>
                                            <div id="general1-collapseOne" className="accordion-collapse collapse"
                                                aria-labelledby="general1-headingOne" data-bs-parent="#accordionGeneral1">
                                                <div className="accordion-body">Sell My PC is a Unit of Smart Techno IT Solution (A Nvidia Authorised Store and Intel Gold Partner as well).
                                                    Our Office is Located in Jaipur. Gaurav Lunia and Shreyansh Jain started this venture way back in 2011 and they have been into this Industry for over 12 Years.
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h6 className="accordion-header" id="general-headingThree">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#general-collapseThree"
                                                    aria-expanded="false" aria-controls="general-collapseThree">
                                                    Where is the Registered Office of Sell My PC?
                                                </button>
                                            </h6>
                                            <div id="general-collapseThree" className="accordion-collapse collapse"
                                                aria-labelledby="general-headingThree" data-bs-parent="#accordionGeneral1">
                                                <div className="accordion-body">Sell My PC (A unit of Smart Techno IT Solution) is located in Jaipur (Rajasthan).
                                                    Our complete Address is:
                                                    B-2/21 Gandhi Path, Vaishali Nagar, Jaipur - 302021
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="accordion accordion-flush" id="accordionGeneral2">
                                        <div className="accordion-item">
                                            <h6 className="accordion-header" id="general2-headingOne">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#general2-collapseOne"
                                                    aria-expanded="false" aria-controls="general2-collapseOne">
                                                    Who is the owner of Sell My PC?
                                                </button>
                                            </h6>
                                            <div id="general2-collapseOne" className="accordion-collapse collapse"
                                                aria-labelledby="general2-headingOne" data-bs-parent="#accordionGeneral2">
                                                <div className="accordion-body">Sell My PC was started by Gaurav Lunia and Shreyansh Jain with a vision to provide easy access to the customers nationwide to Sell their Computer & Laptops.

                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h6 className="accordion-header" id="general2-headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#general2-collapseThree" aria-expanded="false"
                                                    aria-controls="general2-collapseThree">
                                                    What is the Official Contact Details for Sell My PC?
                                                </button>
                                            </h6>
                                            <div id="general2-collapseThree" className="accordion-collapse collapse"
                                                aria-labelledby="general2-headingThree" data-bs-parent="#accordionGeneral2">
                                                <div className="accordion-body">You can contact Sell My PC via Call, WhatsApp or over email.
                                                    Call / WhatsApp: 9982012228
                                                    Email: sellmypc@smarttechno.in
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="pills-token" role="tabpanel" aria-labelledby="pills-token-tab">
                            <div className="row gy-3 gx-5">
                                <div className="col-lg-6">
                                    <div className="accordion accordion-flush" id="accordionToken1">
                                        <div className="accordion-item">
                                            <h6 className="accordion-header" id="token1-headingOne">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#token1-collapseOne"
                                                    aria-expanded="false" aria-controls="token1-collapseOne">
                                                    How Long Does it take in the entire process to Sell the Products?
                                                </button>
                                            </h6>
                                            <div id="token1-collapseOne" className="accordion-collapse collapse"
                                                aria-labelledby="token1-headingOne" data-bs-parent="#accordionToken1">
                                                <div className="accordion-body">Normally it takes around 5-7 days in the entire process if you are Located outside Jaipur. 
                                                    However, there may be some delay if the location is Remote or Countryside. If you are located in Jaipur, it may take even less than a day.

                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h6 className="accordion-header" id="token-headingThree">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#token-collapseThree"
                                                    aria-expanded="false" aria-controls="token-collapseThree">
                                                    Who will pack my Products?
                                                </button>
                                            </h6>
                                            <div id="token-collapseThree" className="accordion-collapse collapse"
                                                aria-labelledby="token-headingThree" data-bs-parent="#accordionToken1">
                                                <div className="accordion-body">You will have to pack the products properly so that the products don’t get damaged on its way.
                                                    We will Provide you a Pickup Slip that you will have to paste it over the Package. For more details you can check out this video. https://smarttechno.in
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="accordion accordion-flush" id="accordionToken2">
                                        <div className="accordion-item">
                                            <h6 className="accordion-header" id="token2-headingOne">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#token2-collapseOne"
                                                    aria-expanded="false" aria-controls="token2-collapseOne">
                                                    Who will Come to Pick up the Products?
                                                </button>
                                            </h6>
                                            <div id="token2-collapseOne" className="accordion-collapse collapse"
                                                aria-labelledby="token2-headingOne" data-bs-parent="#accordionToken2">
                                                <div className="accordion-body">One of our Courier Partner will visit at Your place to Pick Up the products. Just make sure to pack it properly and paste the Pickup/Shipping Label over the Package before the courier partner or pickup person arrives.
                                                    It takes usually 2-3 days for the partner to visit once you confirm.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h6 className="accordion-header" id="token2-headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#token2-collapseThree" aria-expanded="false"
                                                    aria-controls="token2-collapseThree">
                                                    How Long Does it take to Receive the Payment?                                            
                                                </button>
                                            </h6>
                                            <div id="token2-collapseThree" className="accordion-collapse collapse"
                                                aria-labelledby="token2-headingThree" data-bs-parent="#accordionToken2">
                                                <div className="accordion-body">Once we Receive the Product, it is checked thoroughly and if it is Working Condition the payment is made within 24 hours via UPI/NEFT/IMPS to your Account.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="pills-security" role="tabpanel" aria-labelledby="pills-security-tab">
                            <div className="row gy-3 gx-5">
                                <div className="col-lg-6">
                                    <div className="accordion accordion-flush" id="accordionSecurity1">
                                        <div className="accordion-item">
                                            <h6 className="accordion-header" id="security1-headingOne">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#security1-collapseOne"
                                                    aria-expanded="false" aria-controls="security1-collapseOne">
                                                    Which Products are eligible for Sale at Sell My PC?
                                                </button>
                                            </h6>
                                            <div id="security1-collapseOne" className="accordion-collapse collapse"
                                                aria-labelledby="security1-headingOne" data-bs-parent="#accordionSecurity1">
                                                <div className="accordion-body">Almost all computer parts that are not more than 5 Years old and have valid proof of purchase and eligible for Sale with Sell My PC provided that they are in Working Condition.

                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h6 className="accordion-header" id="security-headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#security-collapseTwo" aria-expanded="false"
                                                    aria-controls="security-collapseTwo">
                                                    My Computer/Laptop is Slightly Damaged. Can I Sell it?
                                                </button>
                                            </h6>
                                            <div id="security-collapseTwo" className="accordion-collapse collapse"
                                                aria-labelledby="security-headingTwo" data-bs-parent="#accordionSecurity1">
                                                <div className="accordion-body">Normally we do not buy products that are damaged but still you can share the details to us. 
                                                    We will consider and if the product is in proper working condition, we would initiate the process.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="accordion accordion-flush" id="accordionSecurity2">
                                        <div className="accordion-item">
                                            <h6 className="accordion-header" id="security2-headingOne">
                                                <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#security2-collapseOne"
                                                    aria-expanded="false" aria-controls="security2-collapseOne">
                                                    I have Multiple PC Parts but not the Complete PC. Can I Sell ?
                                                </button>
                                            </h6>
                                            <div id="security2-collapseOne" className="accordion-collapse collapse"
                                                aria-labelledby="security2-headingOne" data-bs-parent="#accordionSecurity2">
                                                <div className="accordion-body">Yes you can. We accept individual parts like Processors, Motherboards, Graphic Cards, Monitors and many others. 
                                                    You can share the details with us. We would definitely try to provide you the best resale value for your parts.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h6 className="accordion-header" id="security2-headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#security2-collapseThree" aria-expanded="false"
                                                    aria-controls="security2-collapseThree">
                                                    I do not have the Original Invoice of My Laptop / PC.  Can I Sell them?
                                                </button>
                                            </h6>
                                            <div id="security2-collapseThree" className="accordion-collapse collapse"
                                                aria-labelledby="security2-headingThree"
                                                data-bs-parent="#accordionSecurity2">
                                                <div className="accordion-body">It’s ok if you do not have the Original Invoice or Purchase Proof of the Product that you wish to Sell. 
                                                    You will simply have to simply accept the Ownership form provided to you.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>


        <section className="cta padding-top padding-bottom" data-aos="fade-up" data-aos-duration="1000">
            <div className="container">
                <div className="cta__wrapper">
                    <div className="cta__content">
                        <p className="section-header__subtitle"> Got anything to sell?</p>
                        <h2 className="section__header__title"> Sell now for cash</h2>
                        <a href="https://wa.me/919982012228" className="default-btn"><span>Click Here!</span></a>
                    </div>
                </div>
            </div> 
        </section>

            
        </>
    ) 
}



export default Home;