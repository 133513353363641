import React, {useState} from 'react'
import { useHistory } from 'react-router-dom'; 
import GoogleLogin from 'react-google-login';


const Login = (props) => { 

    const [credentials, setCredentials] = useState({email: "", password: ""});
    const [loading, setLoading] = useState(false);  

    let history = useHistory();

    const responseGoogle = async (postresponse) => { 

        setLoading(true); 

        const response = await fetch(process.env.REACT_APP_API_ADMIN_URL+"api/google/login", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: postresponse.profileObj.name,
                email: postresponse.profileObj.email,
                imageUrl: postresponse.profileObj.imageUrl,
                googleId: postresponse.profileObj.googleId
            })
        });

        const json = await response.json()

        console.log(json);

        if (json.success){

            // Save the auth token and redirect
            localStorage.setItem('all_users',JSON.stringify(json.data)); 
            localStorage.setItem('portfolio_subscription',json.data.portfolio_subscription); 

            
 
            props.setUserlogin(true);

            setLoading(false);

            props.setNotification({title: json.message, description: "", type: "success"});  

            history.push("/"); 

        } else {
            setLoading(false);
            props.setNotification({title: json.message, description: "", type: "danger"});  
        } 
    }



    const handleSubmit = async (e) => {

        e.preventDefault();

        setLoading(true);

        //$('.login-btn-cls').text('Please Wait..');

        const response = await fetch(process.env.REACT_APP_API_ADMIN_URL+"api/login", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email: credentials.email, password: credentials.password})
        });
        const json = await response.json()
        //console.log(json);
        if (json.success){
            // Save the auth token and redirect
            localStorage.setItem('all_users',JSON.stringify(json.data)); 
            localStorage.setItem('portfolio_subscription',json.data.portfolio_subscription);
            
            //alert(json.data.portfolio_subscription);
 
            props.setUserlogin(true);

            setLoading(false);

            props.setNotification({title: json.message, description: "", type: "success"});  

            history.push("/");

            //let a=JSON.parse((localStorage.getItem("all_users")));

            //alert(a.email);

        } else {
            setLoading(false);
            props.setNotification({title: json.message, description: "", type: "danger"});  
        }
    }

    const onChange = (e)=>{
        setCredentials({...credentials, [e.target.name]: e.target.value})
    }

    return (
        <> 
        <section className="donate-page-section">
            <div className="pattern-layer-one" style={{backgroundImage:"url(assets/images/icons/icon-8.png)"}}></div>
            <div className="auto-container">
                <div className="row clearfix"> 

                    <div className="content-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner-column2">  
                            <div className="image">
                                <img src="assets/images/resource/contact-1.png" alt=""/>
                            </div>
                        </div>
                    </div> 

                    <div className="donate-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="title-box">
                                <h3>Login</h3>
                                <div className="text">Login to your Risehigh account here </div>
                            </div> 
                             
                            <div className="donate-info-tabs"> 
                                <div className="donate-tabs tabs-box">
 
                                     
                                    <div className="tabs-content">
                                         
                                        <div className="tab active-tab" id="prod-monthly">
                                            <div className="content">
                                                 
                                                <div className="donate-form">
                                                    <form method="post" onSubmit={handleSubmit}>
                                                        <div className="row clearfix">  
                                                             
                                                            <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                                <input type="email" name="email" onChange={onChange} value={credentials.email} placeholder="Email Address" required=""/>
                                                            </div>

                                                            <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                                <input type="password" name="password"onChange={onChange} value={credentials.password} placeholder="Password" required=""/>
                                                            </div> 

                                                            <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                                <button type="submit" className="theme-btn btn-style-five login-btn-cls">
                                                                
                                                                {loading && (
                                                                    <i
                                                                    className="fa fa-refresh fa-spin"
                                                                    style={{ marginRight: "5px" }}
                                                                    />
                                                                )}
                                                                
                                                                Login 
                                                                </button>

                                                                <GoogleLogin
                                                                    clientId="1085691765967-buplq754pv8j4v7foe0tfs7iat2vq79i.apps.googleusercontent.com"
                                                                    render={renderProps => (
                                                                        <>
                                                                        <button onClick={renderProps.onClick} disabled={renderProps.disabled} type="button" className="theme-btn btn-style-three-new text-white">
                                                                          Sign Up With Goolge
                                                                        </button>
                                                                        </>
                                                                    )}
                                                                    buttonText="Login"
                                                                    onSuccess={responseGoogle}
                                                                    onFailure={responseGoogle}
                                                                    cookiePolicy={'single_host_origin'}
                                                                />
 

                                                            </div>
                                                            
                                                        </div>
                                                    </form>
                                                </div>
                                                
                                            </div>
                                        </div> 
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
 
        </>
    )
}

export default Login