import React, { useState } from "react"; 
import { useForm } from "react-hook-form";  

const ContactForm = (props) => {   

    const [status, setStatus] = useState("Send Message");

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
       
    const onSubmit = async (form,e) => {  

      e.preventDefault();
      setStatus("Sending...");  

      const { first_name, last_name, email, phone, con_message } = e.target.elements;
      let details = {
        first_name: first_name.value, 
        last_name: last_name.value, 
        email: email.value,
        mobile: phone.value,
        message: con_message.value,
      };

      //this.props.setProgress(60); 

      let response = await fetch(process.env.REACT_APP_API_ADMIN_URL+"api/contactsubmit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(details),
      });

      // this.props.setProgress(90); 

      let result = await response.json();
      if(result.success){
        setStatus("Sent Successfully");
        props.setNotification({title: result.message, description: "", type: "success"}); 
        e.target.reset();
      } else {
        setStatus("Send Message");
        props.setNotification({title: result.message, description: "", type: "danger"});  
      } 

      // this.props.setProgress(100);
      //alert(result.success); 

    }; 

    return (
        <>  
 
          <form method="post" className="form" onSubmit={handleSubmit(onSubmit)} id="contact-form">

            <div className="row">
              
              <div className="col-md-6">
                  <input {...register('first_name', { required: true })} className={"input-form " + (errors.first_name ? 'form-validation-cls' : '')} type="text" name="first_name" id="first_name" placeholder="First Name*"/>
              </div> 

              <div className="col-md-6">
                  <input {...register('last_name', { required: true })} className={"input-form " + (errors.last_name ? 'form-validation-cls' : '')} type="text" name="last_name" id="last_name" placeholder="Last Name*"/>
              </div> 
              
              <div className="col-md-6">
                    <input {...register('email', { required: true })} className={"input-form " + (errors.email ? 'form-validation-cls' : '')} type="email" name="email" id="email" placeholder="Email Address*"/>
              </div>
              
              <div className="col-md-6">
                  <input {...register('phone', { required: false })} className={"input-form " + (errors.phone ? 'form-validation-cls' : '')} type="text" name="phone" id="phone" placeholder="Phone Number*"/>
              </div>

              <div className="col-12">
                  <textarea {...register('con_message', { required: true })} className={"input-form " + (errors.con_message ? 'form-validation-cls' : '')} name="con_message" id="con_message" placeholder="Write Message*"></textarea>
              </div>
 
              <div className="text-center">
                  <button className="common_btn default-btn" type="submit" id="con_submit"><span>{status}</span></button>
              </div>

            </div>
              
          </form> 
 
        </>
    );
};
  
export default ContactForm;